$(function() {
  let scrollY = $(window).scrollTop();
  let switch_flg = false;
  $('.p-header__toggle').on('click', function() {
    if (switch_flg) {

      $(this).removeClass('js-active');
      $('.l-nav').removeClass('js-active');
      $('body').css({
        'position': 'relative',
        'width': '',
        'top': '',
      });
      $(window).scrollTop(scrollY);
      //return false;

      switch_flg = false;//---trueの時はfalseに
    } else {

      $(this).addClass('js-active');
      $('.l-nav').addClass('js-active');
      scrollY = $(window).scrollTop();
      $('body').css({
        'position': 'fixed',
        'width': '100%',
        'top': -1 * scrollY,
      });
      //return false;
      switch_flg = true;//---falseの時はtrueに
    }
  });
  // ページ内スクロール
  const headerHeight = $('.l-header').innerHeight();
  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr('href');
    var target = $(href == '"#"' || href == '""' ? 'html' : href);
    var position = target.offset().top - headerHeight;
    $('html, body').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  const triggerPoint = 0;
  const scrollAnimationElm = document.querySelectorAll('.animation');
  const scrollAnimationFunc = function() {
    for(let i = 0; i < scrollAnimationElm.length; i++) {
      const triggerMargin = triggerPoint;
      const elm = scrollAnimationElm[i];
      let showPos = 0;
      if(elm.dataset.fade_trigger) {
        showPos = document.querySelector(elm.dataset.fade_trigger).getBoundingClientRect().top + triggerMargin;
      } else {
        showPos = elm.getBoundingClientRect().top + triggerMargin;
      }
      if (window.innerHeight > showPos) {
        elm.classList.add('js-scroll');
      }
    }
  }
  window.addEventListener('load', scrollAnimationFunc);
  window.addEventListener('scroll', scrollAnimationFunc);

  setTimeout(function(){
    $('.loading').addClass('js-loading');
    $('.loading-lower').addClass('js-loading');
  },100);

  $('select').on('change',function(){
    $(this).css('background-color','');
  }).trigger('change');

  $('.c-select').css('background-color','#F5F8F6');

});